var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c(
            "a-space",
            [
              _vm.isNew || _vm.isQuotationSubmitted
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "plus",
                        disabled: !_vm.isProcessed
                      },
                      on: { click: _vm.addRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                  )
                : _vm._e(),
              _vm.isNew || _vm.isQuotationSubmitted
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "danger",
                        icon: "delete",
                        disabled: !_vm.selectedRowKeys.length
                      },
                      on: { click: _vm.deleteRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-table",
        {
          attrs: {
            "data-source": _vm.dtSource,
            pagination: false,
            scroll: { y: 320, x: "calc(800px + 50%)" },
            "row-selection": {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onRowSelect
            },
            size: "default"
          },
          scopedSlots: _vm._u([
            {
              key: "expandedRowRender",
              fn: function(record) {
                return [
                  _c(
                    "a-row",
                    [
                      _c("a-col", [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(" " + _vm._s(_vm.$t("lbl_image")) + " ")
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: record.image,
                                expression: "record.image"
                              }
                            ]
                          },
                          [
                            _c("figure", [
                              _c("img", {
                                attrs: {
                                  src: record.thumbnail,
                                  alt: "image",
                                  width: "250px",
                                  height: "auto"
                                }
                              })
                            ]),
                            _c("a-button", {
                              attrs: {
                                icon: "delete",
                                type: "danger",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteFile(record)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !record.image,
                                expression: "!record.image"
                              }
                            ]
                          },
                          [
                            _c("a-icon", { attrs: { type: "file" } }),
                            _vm._v(" " + _vm._s(_vm.$t("lbl_file_not_found")))
                          ],
                          1
                        ),
                        !record.image
                          ? _c(
                              "div",
                              { staticClass: "my-3" },
                              [
                                _c("CUpload", {
                                  attrs: {
                                    accept: ".png, .jpg, .jpeg",
                                    disabled:
                                      !_vm.isNew && !_vm.isQuotationSubmitted
                                  },
                                  on: {
                                    success: function(e) {
                                      return _vm.onUploadDone(e, record)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "a-table-column",
            {
              key: "cutting",
              attrs: { "data-index": "cutting" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("CSelectCutting", {
                        on: { "on-select": _vm.handleDataChange },
                        model: {
                          value: record.cutting,
                          callback: function($$v) {
                            _vm.$set(record, "cutting", $$v)
                          },
                          expression: "record.cutting"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_cutting")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "size",
              attrs: { "data-index": "size" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("a-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("lbl_type_here")
                        },
                        on: { change: _vm.handleDataChange },
                        model: {
                          value: record.size,
                          callback: function($$v) {
                            _vm.$set(record, "size", $$v)
                          },
                          expression: "record.size"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_size")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "vacuumOption",
              attrs: { "data-index": "vacuumOption" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("a-checkbox", {
                        on: { change: _vm.handleDataChange },
                        model: {
                          value: record.vacuumOption,
                          callback: function($$v) {
                            _vm.$set(record, "vacuumOption", $$v)
                          },
                          expression: "record.vacuumOption"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("lbl_use")) + " ")
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_vacuum")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "trayOption",
              attrs: { "data-index": "trayOption" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("a-checkbox", {
                        on: { change: _vm.handleDataChange },
                        model: {
                          value: record.trayOption,
                          callback: function($$v) {
                            _vm.$set(record, "trayOption", $$v)
                          },
                          expression: "record.trayOption"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("lbl_use")) + " ")
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_tray")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "packQty",
              attrs: { "data-index": "packQty" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("CSelectQtyPack", {
                        on: { "on-select": _vm.handleDataChange },
                        model: {
                          value: record.packQty,
                          callback: function($$v) {
                            _vm.$set(record, "packQty", $$v)
                          },
                          expression: "record.packQty"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_qty_pack")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "sticker",
              attrs: { "data-index": "sticker" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("CSelectSticker", {
                        on: { "on-select": _vm.handleDataChange },
                        model: {
                          value: record.sticker,
                          callback: function($$v) {
                            _vm.$set(record, "sticker", $$v)
                          },
                          expression: "record.sticker"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_sticker")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "processNote",
              attrs: { "data-index": "processNote" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text, record) {
                    return [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: _vm.$t("lbl_type_here"),
                          "auto-size": { maxRows: 4 },
                          "allow-clear": ""
                        },
                        on: { change: _vm.handleDataChange },
                        model: {
                          value: record.processNote,
                          callback: function($$v) {
                            _vm.$set(record, "processNote", $$v)
                          },
                          expression: "record.processNote"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_process_notes")))
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }