









































































































































































import { generateUuid } from "@/helpers/common";
import { transformDataURL } from "@/helpers/file-reader";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseQuotationConsumeDetailProcessInfo } from "@/models/interface-v2/quotation.interface";
import { QUOTATION_STATUS } from "@enum/quotation.enum";
import { ResponseUploadData } from "@interface/file.interface";
import { fileServices } from "@service/file.service";
import Vue from "vue";
import { mapState } from "vuex";

export interface IProcessInfo {
  key: string;
  cutting: string | undefined;
  image: string | null; // hold path to file
  packQty: string | undefined;
  processNote: string;
  size: string;
  sticker: string | undefined;
  trayOption: boolean;
  trayType: null | string;
  vacuumOption: boolean;
  vacuumType: null | string;

  thumbnail?: string | ArrayBuffer | null // hold image
}

export default Vue.extend({
  name: "QuotationTableProcessInfo",
  components: {
    CSelectCutting: () => import(/*webpackPrefetch: true*/"@/components/shared/select-cutting/CSelectCutting.vue"),
    CSelectQtyPack: () => import(/*webpackPrefetch: true*/"@/components/shared/select-qty-pack/CSelectQtyPack.vue"),
    CSelectSticker: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sticker/CSelectSticker.vue"),
    CUpload: () => import(/*webpackPrefetch: true*/"@/components/shared/upload/CUpload.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  props: {
    processInfo: {
      type: Array as () => ResponseQuotationConsumeDetailProcessInfo[],
      default: null,
    },
    isProcessed: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      dtSource: [] as IProcessInfo[],
      selectedRowKeys: [] as string[],
      quotationId: "",
    };
  },
  computed: {
    ...mapState({
      detailQuotation: (state: any) => state.quotationStore.detailQuotation,
    }),
    isWarehousePicked(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.WAREHOUSE_PICKED;
    },
    isCancelled(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.CANCELLED;
    },
    isQuotationSubmitted(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.QUOTATION_SUBMITTED;
    },
    isNew(): boolean {
      return this.detailQuotation.status === QUOTATION_STATUS.NEW;
    },
  },
  created() {
    if (this.processInfo.length > 0) {
      this.mapDetail();
    }
    if (this.$route.params.id) {
      this.quotationId = this.$route.params.id;
    } 
  },
  methods: {
    mapDetail(): void {
      this.dtSource = [];
      this.processInfo.forEach((x, i) => {
        this.dtSource.push({
          key: i.toString(),
          cutting: x.cutting,
          image: x.image,
          packQty: x.packQty,
          processNote: x.processNote,
          size: x.size,
          sticker: x.sticker,
          trayOption: x.trayOption,
          trayType: null,
          vacuumOption: x.vacuumOption,
          vacuumType: null,
          thumbnail: "",
        });
        if (x.image) {
          this.getImage(x.image, this.dtSource[i]);
        }
      });
      this.handleDataChange();
    },
    /**
     * get file by filename
     * @param filename uploaded file name
     */
    async getImage(filename: string, record: IProcessInfo): Promise<void> {
        record.thumbnail = "";
      try {
        const res = await fileServices.getFile(filename);
        record.thumbnail = await transformDataURL(res);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    handleDataChange(): void {
      this.emitData();
    },
    onUploadDone({ objectName }: ResponseUploadData, record: IProcessInfo): void {
      record.image = objectName;
      this.getImage(record.image, record);
      this.handleDataChange();
    },
    onRowSelect(rowKeys: string[]): void {
      this.selectedRowKeys = rowKeys;
    },
    addRow(): void {
      const { dtSource } = this;
      const newRow: IProcessInfo = {
        key: generateUuid(),
        cutting: undefined,
        image: null,
        packQty: undefined,
        processNote: "",
        size: "",
        sticker: undefined,
        trayOption: false,
        trayType: null,
        vacuumOption: false,
        vacuumType: null,
        thumbnail: "",
      };
      this.dtSource = [...dtSource, newRow];
    },
    deleteRow(): void {
      const { dtSource } = this;
      if (dtSource.length === 1) {
        this.showNotifError("notif_minimum_process_info");
        return;
      }
      this.dtSource = dtSource.filter(x => {
        return !this.selectedRowKeys.includes(x.key);
      });
      this.selectedRowKeys = [];
      this.emitData();
    },
    emitData(): void {
      const data = this.dtSource.map(x => ({
        cutting: x.cutting,
        image: x.image,
        packQty: x.packQty,
        processNote: x.processNote,
        size: x.size,
        sticker: x.sticker,
        trayType: null,
        trayOption: x.trayOption,
        vacuumType: null,
        vacuumOption: x.vacuumOption,
      }));
      this.$emit("on-change", { data });
    },
    deleteFile(record): void {
      record.image = null;
      record.thumbnail = null;
    },
  }
});
